$app-bg: #171717;
$wrapper-bg: #2f2f2f;
$text-color: #ebebeb;
$border-color: #959595;
$link-color: #959595;
$hover-link-color: #818181;
$button-bg: #171717;
$hover-button-bg: #030303;

a {
  color: $link-color;
}
a:hover {
  color: $hover-link-color;
}

.app {
  background: $app-bg;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wrapper {
  padding: 40px 20px;
}

.inner {
  max-width: 600px;
  margin: auto;
  padding: 40px 20px;
  background: $wrapper-bg;
  border: 1px solid $border-color;
  border-radius: 5px;
}

.logo {
  width: 189px;
  height: 68px;
  background: url("//webcdn.triongames.com/images/common/glyph-logo.png");
  margin: 0 auto 40px;
}

.channel-name {
  color: $text-color;
}

.item {
  padding: 10px 0;
  display: flex;
}

.item-checkbox {
  margin: 0 10px 0 10px;
}

label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.save-button {
  color: $text-color;
  border: 1px solid $border-color;
  background: $button-bg;
  border-radius: 0;
  padding: 12px 44px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
}

.save-button:hover {
  background: $hover-button-bg;
}
.save-button:disabled {
  background: $border-color;
  cursor: not-allowed;
  &:hover {
    background: $border-color;
  }
}

.list-controls {
  padding: 20px 0;
  display: flex;
  justify-content: center;
}

.control {
  border: 0;
  background: none;
  color: $link-color;
  text-decoration: underline;
  cursor: pointer;
}

.control:hover {
  color: $hover-link-color;
}

.loading-wrapper {
  height: 100px;
  display: flex;
  justify-content: center;
}

.error-message {
  text-align: center;
  color: $text-color;
  font-size: 2em;
  font-weight: 700;
}

.success-message {
  h1 {
    text-align: center;
    color: $text-color;
    font-size: 2em;
    font-weight: 700;
  }
  .success-text {
    text-align: center;
    color: $text-color;
  }
}

.description {
  color: $text-color;
  padding: 20px 10px;
}

footer {
  //border-top: 2px solid $border-color;
  //background: $wrapper-bg;
  padding: 40px 20px;
  color: $text-color;
}

.footer-inner {
  //max-width: 600px;
  margin: auto;
  text-align: center;
}

.legal-links-wrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  a {
    margin-left: 10px;
  }
}

.footer-logo {
  background: url("//webcdn.triongames.com/images/common/gamigogroup_logo.png");
  width: 177px;
  height: 84px;
  margin: auto;
}
